@import url('https://fonts.googleapis.com/css2?family=Paprika&display=swap');

body {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ebeef1 !important;
    min-height: 100vh;
    font-family: 'Paprika', cursive;
}


.card {
    width: 500px;
    min-height: 300px;
    border-color: #ffffff !important;
    border-radius: 7px;
    background-color: #e6f7ff !important;
    padding: 30px 35px 40px;
    box-shadow: 10px 10px 10px -1px rgba(23, 162, 187, 0.1),
        -10px -10px 10px -1px rgba(255, 255, 255, 0.70) !important;
}

.card input {
    min-width: 250px !important;
    border: 2px solid #e6f7ff;
    border-radius: 25px;
    font-size: 14px;
    padding: 8px 10px;
    text-align: center;
    margin: 10px auto;
    font-weight: 600;
    font-family: 'Paprika', cursive;
}

.card p {
    font-size: 18px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Paprika', cursive;
}

.card_main {
    min-height: 200px;
    position: relative;
    text-align: center;
}

.card_main img {
    width: 100px;
    height: 100px;

}

.card_main h3 {
    font-size: 18px;
    font-weight: 700;
    color: rgb(87, 87, 87);
    font-family: 'Paprika', cursive;
    margin: 10px;
}

.card_main h2 {
    min-width: 250px;
    font-size: 18px;
    font-weight: 700;
    padding: 10px;
    line-height: 30px;
    position: absolute;
    border-radius: 25px;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 10px -1px rgba(23, 162, 187, 0.1),
        -10px -10px 10px -1px rgba(255, 255, 255, 0.40);
    font-family: 'Paprika', cursive;
}

.card-body h1 {
    font-size: 22px;
    font-weight: 700;
    width: fit-content;
    padding: 15px;
    border-radius: 25px;
    margin: 20px auto;
    box-shadow: 10px 10px 10px -1px rgba(23, 162, 187, 0.1),
        -10px -10px 10px -1px rgba(255, 255, 255, 0.40);
    font-family: 'Paprika', cursive;
}

.card-body select {
    display: block;
    font-size: 16px;
    font-weight: 700;
    width: fit-content;
    padding: 12px 2px;
    background-color: #e6f7ff;
    border-radius: 25px;
    margin: 20px auto;
    box-shadow: 10px 10px 10px -1px rgba(23, 162, 187, 0.1),
        -10px -10px 10px -1px rgba(255, 255, 255, 0.40);
    font-family: 'Paprika', cursive;
    border: none;
}

.card-body select option {
    display: block;
    font-size: 16px;
    font-weight: 700;
    width: fit-content;
    padding: 12px 2px;
    background-color: #e6f7ff;
    border-radius: 25px;
    margin: 20px auto;
    box-shadow: 10px 10px 10px -1px rgba(23, 162, 187, 0.1),
        -10px -10px 10px -1px rgba(255, 255, 255, 0.40);
    font-family: 'Paprika', cursive;
    border: none;
}


.card-body select:target {
    border: none;
}



.main_heading {
    font-size: 22px;
    font-weight: 700;
    width: fit-content;
    padding: 15px;
    border-radius: 25px;
    margin: 20px auto;
    box-shadow: 10px 10px 10px -1px rgba(23, 162, 187, 0.1),
        -10px -10px 10px -1px rgba(255, 255, 255, 0.40);
    font-family: 'Paprika', cursive;
}

.btn {
    font-size: 12px !important;
    font-weight: 600 !important;
    background-color: #fff;
    border: 2px solid rgb(207, 225, 244);
    box-shadow: 10px 10px 10px -1px rgba(23, 162, 187, 0.1),
        -10px -10px 10px -1px rgba(255, 255, 255, 0.40);
    font-family: 'Paprika', cursive !important;
    pointer-events: none !important;
    margin: 10px;
}

.btn-group select {
    font-size: 12px !important;
    font-weight: 600 !important;
    background-color: #e6f7ff;
    border: 2px solid rgb(207, 225, 244);
    box-shadow: 10px 10px 10px -1px rgba(23, 162, 187, 0.1),
        -10px -10px 10px -1px rgba(255, 255, 255, 0.40);
    font-family: 'Paprika', cursive !important;
    border: none;
}

.btn-group select option {
    font-size: 12px !important;
    font-weight: 600 !important;
    background-color: #e6f7ff;
    border: 2px solid rgb(207, 225, 244);
    box-shadow: 10px 10px 10px -1px rgba(23, 162, 187, 0.1),
        -10px -10px 10px -1px rgba(255, 255, 255, 0.40);
    font-family: 'Paprika', cursive !important;
    border: none;
}